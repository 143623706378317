<template>
  <div>
    <vs-button
      icon="add"
      type="line"
      size="small"
      @click="openModal()"
      :disabled="!$atividades.permissoes.includes(8)"
      color="success"
      ><b style="font-size: 16px">Incluir</b></vs-button
    >
    <vs-popup
      v-if="open"
      title="Novo Movimento no Estoque"
      :active.sync="modalCadastrar"
    >
      <div
        class="vs-con-loading__container"
        id="loadingCadastro"
        style="overflow: auto"
      >
        <div class="vs-con-loading__container" id="selectsCadastroME">
          <vs-row
            class="mb-2 mt-1"
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="space-between"
              vs-align="center"
              vs-w="12"
            >
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Colaborador Saída</label
                ><br />
                <el-select
                  filterable
                  clearable
                  remote
                  :remote-method="filtroResponsavel"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  style="width: 270px"
                  :disabled="
                    movimento.id_armazenamento_saida ||
                    (movimento.id_armazenamento_entrada &&
                      movimento.id_armazenamento_saida) ||
                    (movimento.id_colaborador_entrada &&
                      movimento.id_armazenamento_saida)
                      ? true
                      : false
                  "
                  :loading="loading"
                  v-model="movimento.id_colaborador_saida"
                  :popper-append-to-body="false"
                  class="mx-1 mt-1"
                >
                  <el-option
                    v-for="data in responsavel"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Colaborador Entrada</label
                ><br />
                <el-select
                  filterable
                  clearable
                  remote
                  :remote-method="filtroResponsavel"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  :disabled="
                    movimento.id_armazenamento_entrada ||
                    (movimento.id_armazenamento_entrada &&
                      movimento.id_armazenamento_saida) ||
                    (movimento.id_colaborador_saida &&
                      movimento.id_armazenamento_entrada)
                      ? true
                      : false
                  "
                  style="width: 270px"
                  :loading="loading"
                  v-model="movimento.id_colaborador_entrada"
                  :popper-append-to-body="false"
                  class="mx-1 mt-1"
                >
                  <el-option
                    v-for="data in responsavel"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mb-2 mt-1"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="space-between"
              vs-align="center"
              vs-w="12"
            >
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Armazenamento Saída</label
                ><br />
                <el-select
                  filterable
                  clearable
                  style="width: 270px"
                  :disabled="
                    movimento.id_colaborador_saida ||
                    (movimento.id_colaborador_saida &&
                      movimento.id_armazenamento_entrada)
                      ? true
                      : false
                  "
                  v-model="movimento.id_armazenamento_saida"
                  :popper-append-to-body="false"
                  class="mx-1 mt-1"
                >
                  <el-option
                    v-for="armazenamento in armazenamentos"
                    :value="armazenamento.id"
                    :label="armazenamento.nome"
                    :key="armazenamento.id"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Armazenamento Entrada</label
                ><br />
                <el-select
                  filterable
                  clearable
                  style="width: 270px"
                  :disabled="
                    movimento.id_colaborador_entrada ||
                    (movimento.id_colaborador_entrada &&
                      movimento.id_armazenamento_saida)
                      ? true
                      : false
                  "
                  v-model="movimento.id_armazenamento_entrada"
                  :popper-append-to-body="false"
                  class="mx-1 mt-1"
                >
                  <el-option
                    v-for="armazenamento in armazenamentos"
                    :value="armazenamento.id"
                    :label="armazenamento.nome"
                    :key="armazenamento.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          vs-type="flex"
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <div>
              <vs-input
                class="mx-1"
                style="width: 270px"
                type="date"
                label="Data"
                color="dark"
                v-model="movimento.data"
              ></vs-input>
            </div>
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                style="width: 270px"
                type="number"
                min="1"
                @change="renderQtdMovimentos()"
                label="Qtd. Movimentos"
                v-model="quantidade_movimentos"
              ></vs-input>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2 mt-1"
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="w-full mx-1"
              type="text"
              label="Observação"
              v-model="movimento.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-5"
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <!-- <VuePerfectScrollbar
            id="scroll"
            class="scroll-cadastros-movimentos"
            :settings="settings"
            :key="reRenderQtdMovimentos"
          > -->

          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
            v-for="(produto, index) in movimento.produtos"
            :key="index"
          >
            <div>
              <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                >Produto {{ parseInt(index) + 1 }}</label
              ><br />
              <el-select
                filterable
                clearable
                remote
                :remote-method="filtroProduto"
                :loading="loading"
                placeholder="Pesquise"
                loading-text="Buscando..."
                @change="getUnidadeMedida($event, produto)"
                style="width: 270px"
                v-model="produto.id"
                :popper-append-to-body="false"
                class="mx-1 my-1"
              >
                <el-option
                  v-for="produto in produtosOptions"
                  :value="produto.id"
                  :label="produto.nome"
                  :key="produto.id"
                ></el-option>
              </el-select>
            </div>
            <div>
              <vs-input
                class="mx-1"
                style="width: 270px"
                type="number"
                :disabled="!produto.id"
                min="1"
                :icon="produto.unidade"
                icon-after="true"
                color="dark"
                :label="
                  produto.unidade
                    ? 'Quantidade do Produto'
                    : 'Selecione o Produto'
                "
                v-model="produto.quantidade"
              ></vs-input>
            </div>
          </vs-col>
          <!-- </VuePerfectScrollbar> -->
        </vs-row>
        <vs-row
          vs-type="flex"
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              @click="submitForm()"
              class="w-full mt-3 mx-1"
              color="success"
              icon="add"
              type="relief"
              >Incluir</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      movimento: {
        data: this.$formartData.dataFiltro(),
        produtos: [
          {
            id_produto: null,
            quantidade: 1,
            unidade: null
          }
        ]
      },
      produtos: [],
      produtosOptions: [],
      responsavel: {},
      armazenamentos: {},
      tipos_movimento: {},
      open: false,
      modalCadastrar: false,
      loading: false,
      unidade: '',
      quantidade_movimentos: 1,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000
      },
      reRenderQtdMovimentos: 0,
    }
  },
  methods: {
    async openModal () {
      this.open = await true
      this.modalCadastrar = await true
      await this.$vs.loading({
        container: "#selectsCadastroME",
        scale: 0.6
      })
      try {
        await this.getArmazenamento()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#selectsCadastroME > .con-vs-loading")
      }
    },
    async submitForm () {
      if (
        (((this.movimento.id_armazenamento_entrada && this.movimento.id_armazenamento_saida) ||
          (this.movimento.id_colaborador_entrada &&
            this.movimento.id_colaborador_saida) ||
          ((this.movimento.id_armazenamento_entrada && this.movimento.id_colaborador_saida) ||
            (this.movimento.id_colaborador_entrada &&
              this.movimento.id_armazenamento_saida))) &&
          this.movimento.data != null)
      ) {
        await this.Cadastrar()
      } else {
        this.$vs.notify({
          title: "Preencha os campos obrigatorios",
          text:
            "Local de Entrada ou Local de Saída <br> Colaborador da Entrada ou Colaborador da Saída <br> Produto <br> Data <br> Quantidade",
          color: "danger"
        })
      }
    },
    async renderQtdMovimentos () {
      if (this.quantidade_movimentos < 1) {
        this.quantidade_movimentos = 1
      }
      if (parseInt(this.quantidade_movimentos) < this.movimento.produtos.length) {
        this.movimento.produtos = this.movimento.produtos.slice(0, parseInt(this.quantidade_movimentos))
      } else {
        const size = parseInt(this.quantidade_movimentos) - parseInt(this.movimento.produtos.length)
        for (let i = 0; i < size; i++) {
          this.movimento.produtos.push({
            id: null,
            quantidade: 1,
            unidade: null
          })
        }
      }
      this.reRenderQtdMovimentos++
    },
    async Cadastrar () {
      await this.$vs.loading({
        container: "#loadingCadastro",
        scale: 0.6
      })
      try {
        this.movimento.id_colaborador_responsavel = await localStorage.getItem(
          "id"
        )
        const res = await this.$http.post(`movimento_estoque`, this.movimento)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro do Movimento no Estoque N°" + res.id
        }
        await this.$logger(logData)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.modalCadastrar = await false
        this.$emit("update")
        this.movimento = {
          data: this.$formartData.dataFiltro(),
          produtos: [
            {
              id_produto: null,
              quantidade: 1,
              unidade: null
            }
          ]
        },
          await this.$vs.loading.close("#loadingCadastro > .con-vs-loading")
      }
    },
    async getProdutos () {
      try {
        this.produtos = await this.$http.post(`getProdutoByQuery`, { query: null })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async filtroProduto (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const items = this.movimento.produtos.map(item => item.id)
          this.produtosOptions = this.produtos.filter(item => {
            return item.nome.toLowerCase()
              .indexOf(query.toLowerCase()) > -1 || items.indexOf(item.id) > -1
          })
        }, 200)
      } else {
        this.produtosOptions = []
      }
    },
    async getResponsavelByQuery (query) {
      try {
        this.responsavel = await this.$http.post(`getColaboradorByQuery`, {
          query: query
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async filtroResponsavel (query) {
      if (query.length > 2) {
        this.loading = true
        try {
          await this.getResponsavelByQuery(query)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          this.loading = false
        }
      }
    },
    async getArmazenamento () {
      try {
        this.armazenamentos = await this.$http.get(`armazenamento`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getUnidadeMedida (evento, produto) {
      if (evento > 0) {
        produto.unidade = this.produtos.find(x => x.id == evento).sigla.toUpperCase()
      } else {
        produto.unidade = null
      }
    }
  },
  async mounted () {
    await this.getProdutos()
  },
  components: {
    ...components
  }
};
</script>
<style scoped>
.scroll-cadastros-movimentos {
  max-height: 26vh !important;
}
</style>
