<template>
  <div>
    <vs-button
      icon="search"
      type="line"
      :disabled="!$atividades.permissoes.includes(8)"
      size="small"
      color="#3da4fd"
      @click="openModal()"
      ><b style="font-size: 16px;">Filtrar</b></vs-button
    >

    <vs-popup v-if="open" title="Filtrar" :active.sync="modalFiltro">
      <div class="vs-con-loading__container" id="loadingFiltro">
        <div
          class="vs-con-loading__container"
          id="loadingSelectsFiltroMovimento"
        >
          <vs-row
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="space-between"
              vs-align="center"
              vs-w="12"
            >
              <div>
                <label style="color: black; font-size: .85rem;" class="m-0 pl-3"
                  >Produto</label
                ><br />
                <el-select
                  filterable
                  clearable
                  remote
                  :remote-method="filtroProduto"
                  :loading="loading"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  style="width:270px"
                  v-model="filtro.id_produto"
                  :popper-append-to-body="false"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="produto in produtos"
                    :value="produto.id"
                    :label="produto.nome"
                    :key="produto.id"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: .85rem;" class="m-0 pl-3"
                  >Responsável</label
                ><br />
                <el-select
                  filterable
                  clearable
                  remote
                  :remote-method="filtroResponsavel"
                  placeholder="Pesquise"
                  loading-text="Buscando..."
                  style="width:270px"
                  :loading="loading"
                  v-model="filtro.id_colaborador_responsavel"
                  :popper-append-to-body="false"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in responsavel"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
        <vs-row
          class="mb-2 mt-2"
          vs-type="flex"
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <div>
              <label style="color: black; font-size: .85rem;" class="m-0 pl-3"
                >Colaborador Entrada</label
              ><br />
              <el-select
                filterable
                clearable
                remote
                :remote-method="filtroResponsavel"
                placeholder="Pesquise"
                loading-text="Buscando..."
                :disabled="
                  filtro.id_armazenamento_entrada ||
                  (filtro.id_armazenamento_entrada &&
                    filtro.id_armazenamento_saida) ||
                  (filtro.id_colaborador_saida &&
                    filtro.id_armazenamento_entrada)
                    ? true
                    : false
                "
                style="width:270px"
                :loading="loading"
                v-model="filtro.id_colaborador_entrada"
                :popper-append-to-body="false"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="data in responsavel"
                  :value="data.id"
                  :label="data.nome"
                  :key="data.id"
                ></el-option>
              </el-select>
            </div>
            <div>
              <label style="color: black; font-size: .85rem;" class="m-0 pl-3"
                >Colaborador Saída</label
              ><br />
              <el-select
                filterable
                clearable
                remote
                :remote-method="filtroResponsavel"
                placeholder="Pesquise"
                loading-text="Buscando..."
                style="width:270px"
                :disabled="
                  filtro.id_armazenamento_saida ||
                  (filtro.id_armazenamento_entrada &&
                    filtro.id_armazenamento_saida) ||
                  (filtro.id_colaborador_entrada &&
                    filtro.id_armazenamento_saida)
                    ? true
                    : false
                "
                :loading="loading"
                v-model="filtro.id_colaborador_saida"
                :popper-append-to-body="false"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="data in responsavel"
                  :value="data.id"
                  :label="data.nome"
                  :key="data.id"
                ></el-option>
              </el-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2 mt-2"
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <div>
              <label style="color: black; font-size: .85rem;" class="m-0 pl-3"
                >Local Entrada</label
              ><br />
              <el-select
                filterable
                clearable
                style="width:270px"
                :disabled="
                  filtro.id_colaborador_entrada ||
                  (filtro.id_colaborador_entrada &&
                    filtro.id_armazenamento_saida)
                    ? true
                    : false
                "
                v-model="filtro.id_armazenamento_entrada"
                :popper-append-to-body="false"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="armazenamento in armazenamentos"
                  :value="armazenamento.id"
                  :label="armazenamento.nome"
                  :key="armazenamento.id"
                ></el-option>
              </el-select>
            </div>
            <div>
              <label style="color: black; font-size: .85rem;" class="m-0 pl-3"
                >Local Saída</label
              ><br />
              <el-select
                filterable
                clearable
                style="width:270px"
                :disabled="
                  filtro.id_colaborador_saida ||
                  (filtro.id_colaborador_saida &&
                    filtro.id_armazenamento_entrada)
                    ? true
                    : false
                "
                v-model="filtro.id_armazenamento_saida"
                :popper-append-to-body="false"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="armazenamento in armazenamentos"
                  :value="armazenamento.id"
                  :label="armazenamento.nome"
                  :key="armazenamento.id"
                ></el-option>
              </el-select>
            </div>
          </vs-col>
        </vs-row>
        </div>
        <vs-row
          vs-type="flex"
          class="mb-2 "
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <div>
              <vs-input
                class="mx-1"
                style="width:270px"
                type="date"
                color="dark"
                label="Data De"
                v-model="filtro.dataDe"
              ></vs-input>
            </div>
            <div>
              <vs-input
                class="mx-1"
                style="width:270px"
                type="date"
                color="dark"
                label="Data Até"
                v-model="filtro.dataAte"
              ></vs-input>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2 mt-2"
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="w-full mx-1"
              type="text"
              label="Observação"
              v-model="filtro.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          vs-justify="space-between"
          vs-align="center"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-button
              @click="Filtrar()"
              class="w-full mt-3 mx-2"
              color="success"
              type="relief"
              >Filtrar</vs-button
            >
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <vs-button
              @click="(limpar = true), Filtrar()"
              class="w-full mt-3 mx-2"
              color="danger"
              type="relief"
              >Filtrar e Limpar</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      modalFiltro: false,
      open: false,
      produtos: {},
      responsavel: {},
      armazenamentos: {},
      filtro: {},
      limpar: false,
      loading: false
    };
  },
  methods: {
    async openModal() {
      this.limpar = await false;
      this.open = await true;
      this.modalFiltro = await true;
      this.filtro.dataHoje = this.$formartData.dataFiltro()
      await this.$vs.loading({
        container: "#loadingSelectsFiltroMovimento",
        scale: 0.6
      });
      try {
        await this.getArmazenamento();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#loadingSelectsFiltroMovimento > .con-vs-loading"
        );
      }
    },
    async Filtrar() {
      this.$emit("filtro", this.filtro);
      this.modalFiltro = await false;
      if (this.limpar) {
        this.filtro = await {};
      }
    },
    async getProdutoByQuery(query) {
      try {
        this.produtos = await this.$http.post(`getProdutoByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroProduto(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getProdutoByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getResponsavelByQuery(query) {
      try {
        this.responsavel = await this.$http.post(`getColaboradorByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroResponsavel(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getResponsavelByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getArmazenamento() {
      try {
        this.armazenamentos = await this.$http.get(`armazenamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  }
};
</script>
