<template>
  <div>
    <vs-button
      icon="loupe"
      color="#3da4fd"
      type="line"
      @click="openModal()"
      size="small"
    >
      <b style="font-size: 14px">Totalizador</b>
    </vs-button>
    <vs-popup
      v-if="open"
      title="Totalizador Movimentos Estoque"
      :active.sync="modal"
    >
      <div id="totalizador_movimento" class="mb-1 vs-con-loading__container">
        <div v-if="totais.length == 0">
          <div class="pt-20 scroll-totalizador-movimento con-colors">
            <ul class="pt-20">
              <li class="danger-box">
                <h2 class="p-5" style="color: white !important">
                  Nenhum Movimento
                </h2>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <VuePerfectScrollbar
            class="scroll-totalizador-movimento"
            :settings="settings"
          >
            <vs-table
              stripe
              search
              pagination
              :data="totais"
              max-items="100"
              id="tableCliente"
            >
              <template slot="thead">
                <vs-th sort-key="id_produto" style="width: 10%">Produto</vs-th>
                <vs-th sort-key="qtd_recebida" style="width: 7%" id="center"
                  >Recebido</vs-th
                >
                <vs-th sort-key="saldo_inicial" style="width: 7%" id="center"
                  >Devolvido</vs-th
                >
                <vs-th sort-key="saldo_inicial" style="width: 7%" id="center"
                  >Usado</vs-th
                >
                <vs-th sort-key="saldo_inicial" style="width: 7%" id="center"
                  >Saldo</vs-th
                >
                <vs-th sort-key="saldo_inicial" id="center" style="width: 7%">
                  Medida</vs-th
                >
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(tr, index) in data">
                  <vs-td style="font-size: 12px">{{ tr.produto }}</vs-td>
                  <vs-td class="text-center" style="font-size: 12px">
                    <b>{{ tr.qtd_recebida }}</b>
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 12px">
                    <b>{{ tr.qtd_devolvida }}</b>
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 12px">
                    <b>{{ tr.qtd_usada }}</b>
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 12px">
                    <b>{{ tr.saldo }}</b>
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 10px">
                    <b> {{ tr.unidade_medida }}</b>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </VuePerfectScrollbar>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    filtro: {}
  },
  data () {
    return {
      totais: [],
      open: false,
      modal: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      }
    }
  },
  methods: {
    async openModal () {
      this.open = await true
      this.modal = await true
      await this.getTotalizador()
    },
    async getTotalizador () {
      await this.$vs.loading({ container: "#totalizador_movimento", scale: 0.6 })
      try {
        this.totais = await this.$http.post(
          `getTotalizadorMovimentoEstoque`,
          this.filtro
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#totalizador_movimento > .con-vs-loading")
      }
    }
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-totalizador-movimento {
  height: 55vh;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
