<template>
  <div class="w-full">
    <div>
      <div
        id="table_movimento"
        class="cardTable mb-1 vs-con-loading__container"
      >
        <div
          class="mt-1"
          v-if="movimentos.length == 0 || movimentos.length == undefined"
        >
          <div class="pt-20 scroll-movimento con-colors">
            <ul class="pt-20">
              <li class="danger-box">
                <h2 class="p-5" style="color: white !important">
                  Nenhum Movimento no Estoque
                </h2>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-1" v-else>
          <!-- <VuePerfectScrollbar class="scroll-movimento" :settings="settings"> -->
          <vs-table
            maxHeight="72vh"
            id="table_movimento"
            color="success"
            pagination
            maxItems="15"
            class="px-2 scroll-movimento"
            stripe
            :data="movimentos"
          >
            <template slot="thead">
              <vs-th style="width: 5%" sort-key="id">Número</vs-th>
              <vs-th style="width: 10%" sort-key="produto">Produto</vs-th>
              <vs-th style="width: 10%" sort-key="responsavel"
                >Responsável</vs-th
              >
              <vs-th style="width: 10%" id="center" sort-key="quantidade"
                >Quantidade</vs-th
              >
              <vs-th style="width: 10%" id="center" sort-key="saldo_atual"
                >Saldo</vs-th
              >
              <vs-th style="width: 12%" sort-key="saida">Saída</vs-th>
              <vs-th style="width: 12%" sort-key="entrada">Entrada</vs-th>
              <vs-th style="width: 10%" sort-key="data">Data</vs-th>
              <vs-th style="width: 20%">Descrição</vs-th>
              <vs-th>Ações</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                <vs-td
                  :data="tr.id"
                  class="text-center font-semibold"
                  style="font-size: 12px"
                >
                  {{ tr.id }}
                </vs-td>
                <vs-td
                  :data="tr.produto"
                  class="font-bold"
                  style="font-size: 12px"
                  >{{ tr.produto }}</vs-td
                >
                <vs-td
                  :data="tr.responsavel"
                  style="font-size: 12px"
                  class="font-semibold"
                  >{{ tr.responsavel }}</vs-td
                >
                <vs-td
                  style="font-size: 12px"
                  :data="tr.quantidade"
                  class="text-center"
                >
                  {{ tr.quantidade }}
                </vs-td>
                <vs-td
                  :data="tr.saldo"
                  class="text-center"
                  style="font-size: 12px"
                  >{{ tr.saldo ? tr.saldo : 0 }}</vs-td
                >
                <vs-td style="font-size: 12px">{{
                  tr.saida ? tr.saida : "Não informado"
                }}</vs-td>
                <vs-td style="font-size: 12px">{{
                  tr.entrada ? tr.entrada : "Não informado"
                }}</vs-td>
                <vs-td
                  :data="tr.data"
                  class="font-bold"
                  style="font-size: 12px"
                >
                  {{ tr.data | moment("DD/MM/YYYY") }}
                </vs-td>
                <vs-td :data="tr.obs" style="font-size: 12px">
                  {{ tr.obs ? tr.obs : "Sem descrição" }}
                </vs-td>
                <vs-td id="actions">
                  <vs-col vs-w="12" vs-align="center" vs-justify="center">
                    <el-popconfirm
                      confirmButtonText="Confirmar"
                      cancelButtonText="Cancelar"
                      icon="el-icon-info"
                      @confirm="deletar(tr)"
                      iconColor="red"
                      title="Excluir este movimento do estoque?"
                    >
                      <vs-button
                        slot="reference"
                        :disabled="
                          blockButton || !$atividades.permissoes.includes(9)
                        "
                        icon="close"
                        size="small"
                        color="danger"
                      />
                    </el-popconfirm>
                  </vs-col>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!-- </VuePerfectScrollbar> -->
        </div>
      </div>
      <vx-card no-shadow card-background="dark">
        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vs-col
            vs-w="3"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <span style="color: white; font-size: 14px" class="ml-2"
              >Movimentos: {{ movimentos.length }}</span
            >
          </vs-col>

          <vs-col
            vs-w="9"
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
          >
            <vs-col vs-w="2.25" vs-justify="flex-end" vs-align="flex-end">
              <Cadastrar @update="getMovimentoEstoque" />
            </vs-col>
            <vs-col vs-w="2.25" vs-justify="flex-end" vs-align="flex-end">
              <Filtro @filtro="Filtrar" />
            </vs-col>
            <vs-col vs-w="2.25" vs-justify="flex-end" vs-align="flex-end">
              <vs-button
                icon="get_app"
                type="line"
                @click="exportToExcel()"
                color="#FFCA00"
                size="small"
              >
                <b style="font-size: 16px">Excel</b>
              </vs-button>
            </vs-col>
            <vs-col vs-w="2.25" vs-justify="flex-end" vs-align="flex-end">
              <Totalizador :filtro="filtro" />
            </vs-col>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import Cadastrar from "./components/cadastrar";
import Filtro from "./components/filtro";
import Totalizador from "./components/totalizador";
export default {
  data() {
    return {
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.5,
        height: 1000,
      },
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
      },
      movimentos: {},
      blockButton: false,
    };
  },
  methods: {
    async getMovimentoEstoque() {
      this.blockButton = await true;
      await this.$vs.loading({ container: "#table_movimento", scale: 0.6 });
      try {
        this.movimentos = await this.$http.post(
          `getMovimentoEstoque`,
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.blockButton = await false;
        await this.$vs.loading.close("#table_movimento > .con-vs-loading");
      }
    },
    async Filtrar(filtro) {
      this.filtro = await filtro;
      await this.getMovimentoEstoque();
    },
    async deletar(movimento) {
      try {
        this.blockButton = await true;
        await this.$http.put(`movimento_estoque/` + movimento.id, {
          lixeira: 1,
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão do Movimento de Estoque N°" + movimento.id,
        };
        await this.$logger(logData);
        await this.getMovimentoEstoque();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.blockButton = await false;
      }
    },
    async exportToExcel() {
      try {
        import("@/vendor/Export2Excel").then(async (excel) => {
          let headerTitle = [
            "Número",
            "Produto",
            "Responsável",
            "Quantidade",
            "Saldo",
            "Entrada",
            "Saida",
            "Data",
            "Observação",
          ];
          let headerVal = [
            "id",
            "produto",
            "responsavel",
            "quantidade",
            "saldo",
            "entrada",
            "saida",
            "data",
            "obs",
          ];
          const list = this.movimentos;
          const data = await this.formatJson(headerVal, list);
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: "Movimento de Estoque",
            autoWidth: true,
            bookType: "xlsx",
          });
        });
        this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
  async mounted() {
    await this.getMovimentoEstoque();
  },
  components: {
    ...components,
    Filtro,
    Cadastrar,
    Totalizador,
  },
};
</script>
<style lang="scss">
.scroll-movimento {
  height: 82vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.el-popconfirm__main {
  font-size: 12px !important;
}
</style>
